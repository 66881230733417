<template>
  <div class="body">
    <div class="head">
      <Head></Head>
    </div>
    <div class="manage-box">
      <div class="manage-title-bg1">
        <img
          class="bg1"
          src="@/image/travelService/travelService-new-title.png"
          alt=""
          style="width: 100%"
        />
      </div>
      <div class="manage-title-bg2" @click="goDetail('/yijiyou')">
        <img
          class="bg2"
          src="@/image/travelService/travelService-new-one.png"
          alt=""
          style="width: 100%; border-radius: 25px"
        />
      </div>
      <div class="manage-title-bg4" @click="goDetail('/shuziganbo')">
        <img
          class="bg2"
          src="@/image/travelService/travelService-new-two.png"
          alt=""
          style="width: 100%; border-radius: 25px"
        />
      </div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
// import 'swiper/dist/css/swiper.css'
//
// import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: "prize",
  components: {
    Head,
    Foot,
  },

  data() {
    return {
      // isFixed: true
    };
  },
  computed: {},
  mounted() {
    // window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    gotuiframe: function (url) {
      this.$router.push("/iframe?url=" + url);
    },
    goDetail: function (route) {
      this.$router.push({ path: route });
    },
    // handleScroll() {
    //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   const ceilingEle = this.$refs.ceiling
    //   if (scrollTop > ceilingEle.offsetTop) {
    //     this.isFixed = true
    //   } else {
    //     this.isFixed = false
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
.head {
  position: sticky;
  top: 0px;
  z-index: 99999999;
}
.manage-title-bg2 {
  margin-top: -70vh;
  padding: 1vh 8vw;
}
.manage-title-bg3,
.manage-title-bg4 {
  margin-top: 5vh;
  padding: 1vh 8vw;
}

.bg2:hover {
  cursor: pointer;
  animation: pulse;
  animation-duration: 1.5s;
}
</style>
